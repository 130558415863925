import React from 'react'
import { Container } from 'react-bootstrap'
import Logo from '../Resources/footer-logo-mia.svg'
import IconInstagram from '../Resources/icons-redes-ig.svg'
import IconLinkedin from  '../Resources/icons-redes-in.svg'
import IconFacebook from  '../Resources/icons-redes-fb.svg'
import ReneArq from  '../Resources/rene-arquitectura.svg'
import IconMia from  '../Resources/footer-icon-mia.svg'



const Footer = () => {
  return (
    <footer className='p-0 m-0' >
      <Container className='footerContainer' >
        <div className="container text-center m-0 p-0 ">
          <div className="row footer__row--iconContainer">
            
            <div className='col-md-12 d-flex flex-row'>
              <img src={IconMia} alt=""  className='footer__items--icon'/>
            </div>

            <div className='col col-ms-0 col-md-2 footer__items '></div>
            <div className='col-12 col-md-3 box footer__items'>
              <div>
                <p className='footer__titulo'>DISTRITO MIA CANNING</p>
                <p className='footer__texto'>RUTA 52 Y DUPUY, CANNING<br/>
                  CP 1804, ESTEBAN ECHEVERRIA
                </p>
              </div>
              <p className='footer__texto'>BUENOS AIRES, ARGENTINA</p>
            </div>
            <div className='col-sm-12 col-md-2 box footer__items '>
              <div>
                <p className='footer__titulo text-center'>COMERCIALIZA</p>
                <img src="./images/epicel.png" alt="Epicel" style={{textAlign: 'left'}} className="d-block footer__items--comercializa"/>
              </div>
              <p className='footer__texto'>4295-6364 WWW.EPICEL.COM.AR</p>
            </div>
            <div className='col-sm-12 col-md-3 box footer__items footer__items--contRedes'>
              <div>
                <p className='footer__titulo'>SEGUINOS EN</p>
                <span className='footer__items--redes'>
                  <a href="https://www.facebook.com"  target="_blank" className='navbarlinks__btn'><img style={{height: '50px'}} src={IconFacebook} alt="Facebook" /></a>
                  <a href="https://www.instagram.com" target="_blank" className='navbarlinks__btn'><img style={{height: '50px'}} src={IconInstagram} alt="Istagram" /></a>
                  <a href="https://www.linkedin.com"  target="_blank" className='navbarlinks__btn'><img style={{height: '50px'}} src={IconLinkedin} alt="Linkedin" /></a>
                </span>
              </div>
              <p className='footer__texto'>WWW.MIACANNING.COM.AR</p>
            </div>
            <div className='col-sm-12 col-md-2 box footer__items'>
              <div></div>
              <img src={ReneArq} alt="Reñe Arquitectura" className='footer__renie d-block w-75 text-center' />
            </div>
          </div>
        </div>  
      </Container>
    </footer>
  )
}

export default Footer