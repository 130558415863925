import React from 'react'
import { Container } from 'react-bootstrap'
import Carrousel from './Carrousel'
import HomeAccesos from './HomeAccesos'
import NavbarSite from './NavbarSite'
import HomeBanner from './HomeBanner'

const Home = () => {
  return (
    <Container className='p-0 mb-0' >
         <div><h1 className='navbarH1'>DISTRITO</h1></div>
        <NavbarSite/>
        <Carrousel />
        <div><h2 className='bannerH2'><strong>UN DISTRITO</strong> MUCHAS POSIBILIDADES</h2></div>
        <HomeAccesos/>
        <HomeBanner />
    </Container>
  )
}

export default Home