import React from 'react'
import { Container } from 'react-bootstrap'
import NavbarSite from './NavbarSite'
import IconMia from  '../Resources/mia-headers.svg'
import Triangulo from '../Resources/icono-triangulo.svg'

const CentroComercial = () => {
  return (
    <Container fluid className='p-0 m-0'>
        <Container className='p-0 mb-0' >
            <div><h1 className='navbarH1'>DISTRITO</h1></div>
            <NavbarSite/>
            <div className='containerHeaders distritoContainer'>
                <div className='row'>
                    <div className='col-md-8 col-lg-9 col-xl-10'>
                        <p className='distritoContainer__titulo'>
                            <span className='distritoContainer__titulo--bold'>DISTRITO MIA CANNING</span><br />
                        <span className='distritoContainer__titulo--light'> CON UN CENTRO COMERCIAL PARA INTEGRAR PLACER, TRABAJO Y ENTRETENIMIENTO.</span>
                        </p>
                    </div>
                    <div className='col-md-4 col-lg-3 col-xl-2 distritoContainer__mia'>
                        <img src={IconMia} alt="" className='distritoContainer__mia--img' />
                    </div>
                </div>
            </div>

            <div className='containerBackground'>
                <div className='row'>
                    
                    <div className='col-12 imagenContainer text-center'>
                        <img src="images/centro-comercial/centro-comercial-1.jpg" className='imagenRedondeada' alt="Render aereo del Centro Comercial" />
                    </div>
                    <div className='col-12'>
                        <div className='col-md-8 col-lg-9 col-xl-10'>
                            <p className='distritoContainer__titulo titulosInteranas'>
                                <span className='distritoContainer__titulo--bold distritoContainer__titulo--gris'>DISTRITO MIA CANNING</span><br />
                            <span className='distritoContainer__titulo--light distritoContainer__titulo--gris'> UN DISTRITO. MUCHAS POSIBILIDADES</span>
                            </p>
                        </div>
                        <div className='textosBloques'>
                            <p>  <img src={Triangulo} alt="" className='textosBloques__icon' /> </p>
                            <p className='textosBloques__txt'>
                                <strong>El Centro Comercial </strong>funcionará como soporte de entretenimiento y servicios de este Mega  desarrrollo.  Será el lugar ideal para hacer una pausa en el día, disfrutar de la mejor gastronomía o comprar eso que tanto quería regalar o regalarse. <strong>De fácíl acceso y con la posibilidad de estacionamiento asegurado</strong> para todos los consumidores por sus grandes dimensiones y su estadía sea una gratificante experiencia. <strong>Distrito MIA Canning</strong> contará con amplios locales  y oficinas de excelente calidad constructiva y detalles de terminación con materiales de primera línea.
                            </p>
                        </div>
                    </div>
        
                    <div className='col-12 imagenContainer text-center'>
                     <img src="images/centro-comercial/centro-comercial-2.jpg" className='imagenRedondeada' alt="Render aereo del Centro Comercial" />
                    </div>
                    <div className='col-12'>
                        <div className='col-md-8 col-lg-9 col-xl-10'>
                        <p className='distritoContainer__titulo titulosInteranas'>
                            <span className='distritoContainer__titulo--bold distritoContainer__titulo--gris'>DISTRITO MIA CANNING</span><br />
                        <span className='distritoContainer__titulo--light distritoContainer__titulo--gris'> UNA INVERSIÓN INTELIGENTE</span>
                        </p>
                        </div>
                        <div className='textosBloques'>
                        <p>  <img src={Triangulo} alt="" className='textosBloques__icon' /> </p>
                        <p className='textosBloques__txt'>
                        <strong>Si, lo mire por dónde lo mire será una gran inversión,</strong> sentir su habitat natural, será como sumergirse en miles de vivencias irrepetibles, olvidarse del vértigo del trabajo, acordarse de usted.  <br /><br />Disfrutar y ver como disfrutan, vivir toda la semana con la sonrisa del viernes. Sentir que está en su mejor momento y lo más importante, que<strong> ese momento puede empezar cuando usted lo decida.</strong>
                            </p>
                        </div>
                    </div>
                    
                    <div className='col-12 imagenContainer text-center'>
                        <img src="images/centro-comercial/centro-comercial-3.jpg" className='imagenRedondeada' alt="Render aereo del Centro Comercial" />
                    </div>
                    <div className='col-12 pb-5'>
                        <div className='col-md-8 col-lg-9 col-xl-10'>
                            <p className='distritoContainer__titulo titulosInteranas'>
                                <span className='distritoContainer__titulo--bold distritoContainer__titulo--gris'>DISTRITO MIA CANNING</span><br />
                                <span className='distritoContainer__titulo--light distritoContainer__titulo--gris'> RODEADO DE COUNTRIES, BARRIOS CERRADOS Y CLUBS DE CAMPO</span>
                            </p>
                        </div>
                        <div className='textosBloques'>
                            <p>  <img src={Triangulo} alt="" className='textosBloques__icon' /> </p>
                            <p className='textosBloques__txt'>
                            <strong>Más de 70 barrios privados, clubs de campos y countries podemos encontrar en su cercanía, </strong>apróximadamente 4000 familias viven y consumen en la zona. Entre los más conocidos podemos destacar a Country Mi Refugio, El Sosiego, El Lauquen,  Don Joaquin, Cruz del Sur, San Eliseo, Horizontes al Sur,  Chacras de Canning Club de Campo,Los Rosales, El Rocio, La Magdalena, Saint Thomas Sur, Saint Thomas Norte, Saint Thomas Este, Saint Thomas Oeste ,Saint Thomas Centro, Echeverría del Lago, Campos de Echeverría, La Horqueta de Echeverría, Solar del Bosque, Santa Rita, Santa Juana, Santa Inés, El Venado, Venado II, El Centauro, Los Rosales, Brickland, Los Naranjos 1 y 2, La Providencia, Malibu, Los Talas, Lares de Canning, Lagos de Canning, Fincas del Alba, Las Flores, Don Marcelo, Tiempos de Canning, Santa Clara al Sur, Las Victorias, Terralagos, etc...  <br /><br />
                            <strong>En definitiva, Distrito MIA Canning será un gran lugar de encuentro para el público de la zona.</strong><br /><br />
                            <span className='textosBloques__txt--destacadoBold '>150.000 POTENCIALES CLIENTES</span>,<span className='textosBloques__txt--destacadoLight '> LO ESTÁN ESPERANDO</span>
                            </p>
                        </div>
                    </div>  

                </div>
            </div>
   
        </Container>
        <div className='p-0 m-0'>
            <img src="images/centro-comercial/banner.jpg" className='d-block w-100' alt="Render aereo del Centro Comercial" />
        </div>
        <Container className='p-0 mb-0' >
            <div className='containerBackground'>
                <div className='row'>
                    
                    <div className='col-12 imagenContainer  text-center'>
                        <img src="images/centro-comercial/centro-comercial-4.jpg" className='imagenRedondeada' alt="Render aereo del Centro Comercial" />
                    </div>
                    <div className='col-12 '>
                        <div className='col-md-8 col-lg-9 col-xl-10'>
                            <p className='distritoContainer__titulo titulosInteranas'>
                                <span className='distritoContainer__titulo--bold distritoContainer__titulo--gris'>DISTRITO MIA CANNING</span><br />
                            <span className='distritoContainer__titulo--light distritoContainer__titulo--gris'> CON LA EXPERIENCIA DE<br /> UN GRAN RECORRIDO</span>
                            </p>
                        </div>
                        <div className='textosBloques imagenContainer__mia'>
                            <p>  <img src={Triangulo} alt="" className='textosBloques__icon' /> </p>
                            <p className='textosBloques__txt'>
                                Diseñado por el estudio <strong>Reñé Arquitectura,</strong> de vasta experiencia en desarrollos comerciales y residenciales, <strong>Distrito MIA Canning</strong> es un ambicioso <strong>proyecto de los desarrolladores y propietarios de Las Alondras Cariló, <a href='https://www.lasalondrascarilo.com.ar' target={"_blank"} rel="noreferrer">www.lasalondrascarilo.com.ar,</a></strong> el Centro Comercial más importante de Cariló,  <strong>que desde hace más de 25 años es el centro de la escena en la exclusiva playa argentina.</strong> Distrito Mia Canning, una oportunidad única en el mercado, <strong>para vivir, para invertir, para rentar. </strong>
                            </p>
                        </div>
                    </div>
                   
                </div>
            </div>



        </Container>
    </Container>
  )
}

export default CentroComercial