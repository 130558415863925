import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const HomeAccesos = () => {
  return (
    <Container>
        <div className='row'>
            <div className='col-12 col-md-6 m-0 p-0 homeAccesos' > <Link to={'/distrito/'}><img className='d-block w-100 p-0 m-0' src="./images/accesos-distrito.jpg" alt="Acceso Distritos" loading='lazy' /></Link></div>
            <div className='col-12 col-md-6 m-0 p-0 homeAccesos' > <Link to={'/ubicacion/'}><img className='d-block w-100 p-0 m-0' src="./images/accesos-ubicacion.jpg" alt="Ubicacion" loading='lazy' /></Link></div>
            <div className='col-12 col-md-6 m-0 p-0 homeAccesos' > <Link to={'/macro-fracciones/'}><img className='d-block w-100 p-0 m-0' src="./images/accesos-macro-fracciones.jpg" alt="Macro Fracciones" loading='lazy' /></Link></div>
            <div className='col-12 col-md-6 m-0 p-0 homeAccesos' > <Link to={'/centro-comercial/'}><img className='d-block w-100 p-0 m-0' src="./images/accesos-centro-comercial.jpg" alt="Centro Comercial" loading='lazy' /></Link></div>
        </div>
    </Container>
  )
}

export default HomeAccesos