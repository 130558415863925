import React from 'react'
import { Navbar, Container, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import MiaCanning from '../Resources/mia-canning.svg'
import IconInstagram from '../Resources/icon-instagram.svg'
import IconContacto from '../Resources/icon-contact.svg'
import IconLocation from '../Resources/icon-location.svg'

const NavbarSite = () => {
  return (
    <Container className="row" style={{margin: "0 auto"}} >
      <div className="col-7 col-md-9 text-center">
        <Link to={'/'} style={{ cursor: 'pointer'}}>
          <img src={MiaCanning} alt="Mia Canning" className='navbar-img' />
         </Link>
      </div>
      <div className="col-5 col-md-3 navbarLayout">
        <Nav className="justify-content-end navbarLayout__items" activeKey="/home">
          <Nav.Item className=" navbarlinks__btn navbarlinks__btn--right ">
            <Link to={'/el-distrito'} className='link__reset'> El Distrito </Link>
          </Nav.Item>
          <Nav.Item className=" navbarlinks__btn navbarlinks__btn--right ">
            <Link to={'/galeria'} className='link__reset'>Galeria  </Link>
          </Nav.Item>
          <Nav.Item className=" navbarlinks__btn navbarlinks__btn--right ">
            <Link to={'/contacto'} className='link__reset'>Contacto </Link>
          </Nav.Item>
        </Nav>
        <Nav className="justify-content-end navbarLayout__icons" activeKey="/home">
          <Nav.Item className=" navbarlinks__btn navbarlinks__btn--right ">
            <Link to={'/el-distrito'} className='link__reset'>
              <img src={IconInstagram} alt="Instagram"  className='navbar__icon'/>
            </Link>
          </Nav.Item>
          <Nav.Item className=" navbarlinks__btn navbarlinks__btn--right ">
            <Link to={'/galeria'} className='link__reset'>
              <img src={IconContacto} alt="Contacto"  className='navbar__icon'/>
            </Link>
          </Nav.Item>
          <Nav.Item className=" navbarlinks__btn navbarlinks__btn--right">
            <Link to={'/contacto'} className='link__reset'>
              <img src={IconLocation} alt="Ubicacion" className='navbar__icon'/>
            </Link>
          </Nav.Item>
        </Nav>
      </div>
    </Container>
  )
}

export default NavbarSite