import React from 'react'
import { Container } from 'react-bootstrap'

const HomeBanner = () => {
  return (
    <Container className="container mt-5 mb-5">
        <div className='row justify-content-center HomeBannerContainer'>
          <div className='col-12 col-md-3 text-center'>
            <p className='HomeBannerContainer__number HomeBannerContainer__number--1'>11,5</p>
            <p className='HomeBannerContainer__text'>HECTÁREAS</p>
          </div>
          <div className='col-12 col-md-3 text-center'>
            <p className='HomeBannerContainer__number HomeBannerContainer__number--2'>28</p>
            <p className='HomeBannerContainer__text'>LOCALES</p>
          </div>
          <div className='col-12 col-md-3 text-center'>
            <p className='HomeBannerContainer__number HomeBannerContainer__number--3'>14</p>
            <p className='HomeBannerContainer__text'>OFICINAS</p>
          </div>
        </div>
    </Container>
  )
}

export default HomeBanner