import React from 'react'
import { Container } from 'react-bootstrap'
import NavbarSite from './NavbarSite'
import IconMia from  '../Resources/mia-headers.svg'
import Triangulo from '../Resources/icono-triangulo.svg'

const Distrito = () => {
  return (
    <Container className='p-0 mb-0' >
         <div><h1 className='navbarH1'>DISTRITO</h1></div>
        <NavbarSite/>
        <div className='containerHeaders distritoContainer'>
          <div className='row'>
            <div className='col-md-8 col-lg-9 col-xl-10'>
              <p className='distritoContainer__titulo'>
                <span className='distritoContainer__titulo--bold'>DISTRITO MIA CANNING</span><br />
               <span className='distritoContainer__titulo--light'> UN DISTRITO. MUCHAS POSIBILIDADES.</span>
              </p>
            </div>
            <div className='col-md-4 col-lg-3 col-xl-2 distritoContainer__mia'>
              <img src={IconMia} alt="" className='distritoContainer__mia--img' />
            </div>
          </div>
        </div>
      <div className='containerBackground'>
        <div className='row'>
          <div className='col-12 imagenContainer text-center'>
            <img src="images/distrito/distrito-render-1.jpg" className='imagenRedondeada' alt="Render aereo del distrito Mia Canning" />
          </div>
          <div className='col-12'>
            <div className='textosBloques'>
              <p>  <img src={Triangulo} alt="" className='textosBloques__icon' /> </p>
              <p className='textosBloques__txt'>
              <strong>Distrito Mia Canning</strong> se encuentra emplazado en una superficie de 11,5 hectáreas con 
características únicas tanto en lo comercial como residencial. <strong>Contará con un Centro Comercial</strong> como protagonista, de 28 locales, 14 oficinas y amplio estacionamiento al mejor estilo stripmall de Miami..
                </p>
            </div>
          </div>
          <div className='col-12 imagenContainer text-center'>
            <img src="images/distrito/distrito-render-2.jpg" className='imagenRedondeada' alt="Render aereo del distrito Mia Canning" />
          </div>
          <div className='col-12 pb-5'>
            <div className='textosBloques'>
              <p>  <img src={Triangulo} alt="" className='textosBloques__icon' /> </p>
              <p className='textosBloques__txt'>
                <strong>Las fracciones multi familiares que se encuentran a la venta,</strong> conformarán un proyecto global homogéneo en cuanto a tipologías, características y emplazamiento teniendo en cuenta la posibilidad de generar un vínculo auténtico con la naturaleza circundante.
                </p>
            </div>
          </div>
        </div>
      </div> 
    </Container>
  )
}

export default Distrito